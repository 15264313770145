import qs from "qs";
import APIUtils from "../../../utils/APIUtils";

export const QUERY_INVESTIGATION = "QUERY_INVESTIGATION";

export const queryInvestigation = (companyId, params = {}) => {
  let queryStr = qs.stringify(params, { addQueryPrefix: true });
  return {
    type: QUERY_INVESTIGATION,
    payload: APIUtils.get(`/companies/${companyId}/publications/investigations${queryStr}`)
  };
};
