import React from "react";
import T from "i18n-react";
import moment from "moment";
import queryString from "query-string";
import classNames from "classnames";
import config from "../config";
import update from "immutability-helper";
import Flatpickr from "react-flatpickr";
import se from "../utils/flatpickrLang";
import { push } from "connected-react-router";
import { connect } from "react-redux";
import { queryEsign } from "../actions/company/list/CompanyEsignAction";
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";

const mapStateToProps = ({ EsignService: { signs } }) => {
  return {
    signs
  };
};

const mapDispatchToProps = () => dispatch => ({
  dispatch,
  queryEsign: (companyId, params) =>
    dispatch(queryEsign(companyId, Object.assign({}, params)))
});

export class Esign extends React.Component {
  state = {
    propertyName: "noticeName",
    reverse: false,
    params: {
    },
    more: false
  };
  componentDidMount() {
    let query = queryString.parse(this.props.location.search);
    let localSelectedCompany = window.localStorage.selectedCompanyObj
      ? JSON.parse(window.localStorage.selectedCompanyObj)
      : null;
    if (localSelectedCompany) {
      this.setState(
        {
          params: query,
          companyId: localSelectedCompany.id
        },
        () => {
          this.props.queryEsign(this.state.companyId, this.state.params);
        }
      );
    }
  }
  componentDidUpdate(nextProps) {
    if (this.props.signs !== nextProps.signs) {
      this.setState(
        {
          more: this.props.signs.length === 21 ? true : false,
          propertyName: "signName",
          reverse: true,
          signs: this.props.signs
        },
        () => {
          this.toggleSortSign(this.state.propertyName);
        }
      );
    }

    if (this.props.location.search !== nextProps.location.search) {
      let query = queryString.parse(this.props.location.search, {
        ignoreQueryPrefix: true
      });
      this.setState(
        {
          params: query
        },
        () => {
          this.props.queryEsign(this.state.companyId, query);
        }
      );
    }
  }
  toggleSortSign = propertyName => {
    let signs = this.state.signs.sort((a, b) => {
      if (propertyName === "signCreadtedAt") {
        return this.state.reverse
          ? a.createdAt < b.createdAt
            ? -1
            : 1
          : a.createdAt >= b.createdAt
          ? -1
          : 1;
      } else if (propertyName === "signName") {
        return this.state.reverse
          ? a.publication.name < b.publication.name
            ? -1
            : 1
          : a.publication.name >= b.publication.name
          ? -1
          : 1;
      } else if (propertyName === "signDeadline") {
        return this.state.reverse
          ? a.deadline < b.deadline
            ? -1
            : 1
          : a.deadline >= b.deadline
          ? -1
          : 1;
      }
    });
    this.setState({
      propertyName: propertyName,
      reverse: !this.state.reverse,
      signs: signs
    });
  };
  checkDueAt = () => {
    let query = queryString.parse(this.props.location.search, {
      ignoreQueryPrefix: true
    });
    this.setState(
      {
        params: update(this.state.params, {
          deadline: {
            $set:
              this.state.params.deadline &&
              this.state.params.deadline !== "Invalid date"
                ? moment(this.state.params.deadline).format("YYYY-MM-DD")
                : ""
          }
        })
      },
      () => {
        query = Object.assign({}, query, this.state.params);
        Object.keys(query).map((key, index) => {
          if (!query[key]) {
            delete query[key];
          }
        });
        this.props.dispatch(
          push({
            pathname: this.props.match.pathname,
            search: queryString.stringify(query)
          })
        );
      }
    );
  };
  filterSign(params) {
    let query = params;
    if (params) {
      query = queryString.parse(this.props.location.search, {
        ignoreQueryPrefix: true
      });
      query = Object.assign({}, query, params);
      Object.keys(query).map((key, index) => {
        if (!query[key]) {
          delete query[key];
        }
      });
    }
    if (!params) {
      query = {};
    }
    this.props.dispatch(
      push({
        pathname: this.props.match.pathname,
        search: queryString.stringify(query)
      })
    );
  }
  render() {
    let query = queryString.parse(this.props.location.search);
    let params = this.state.params;
    return (
      <div className="content-holder">
        <div className="form__wrap--embed cf">
          <header className="">
            <div className="customers-dashboard__header-column filter-left">
              <h1>{T.translate("publication.ESIGN")}</h1>
            </div>
            <div className="customers-dashboard__header-column filter-right">
              <div className="customers-dashboard__filter-wrap type-text input-date">
                <Flatpickr
                  placeholder={T.translate("deadline")}
                  value={this.state.params.deadline}
                  onChange={v => {
                    this.setState(
                      {
                        params: update(this.state.params, {
                          deadline: {
                            $set: moment(new Date(v)).format("YYYY-MM-DD")
                          }
                        })
                      },
                      this.checkDueAt
                    );
                  }}
                  options={{
                    time_24hr: true,
                    locale: se
                  }}
                />
              </div>
              <Dropdown
                className="select__wrap"
                isOpen={this.state.toggleOpenOrder}
                toggle={() => {
                  this.setState({
                    toggleOpenOrder: !this.state.toggleOpenOrder
                  });
                }}
              >
                <DropdownToggle className="select__button button">
                  {!this.state.params.status ||
                  (this.state.params.status &&
                    this.state.params.status === "active") ? (
                    <span>{T.translate("activated")}</span>
                  ) : null}
                  {this.state.params.status &&
                  this.state.params.status === "inactive" ? (
                    <span>{T.translate("inactive")}</span>
                  ) : null}
                  {this.state.params.status &&
                  this.state.params.status === "complete" ? (
                    <span>{T.translate("complete")}</span>
                  ) : null}
                  <i
                    className={
                      "fa " +
                      (this.state.toggleOpenOrder
                        ? "fa-caret-up"
                        : "fa-caret-down")
                    }
                  />
                </DropdownToggle>
                <DropdownMenu>
                  <ul className="select__list type-text">
                    <li
                      className={classNames("select__item text-height", {
                        active:
                          !this.state.params.status ||
                          (this.state.params.status &&
                            this.state.params.status === "active")
                      })}
                      onClick={() => {
                        this.setState(
                          {
                            toggleOpenOrder: false,
                            params: update(this.state.params, {
                              status: {
                                $set: "active"
                              }
                            })
                          },
                          () => {
                            query = Object.assign({}, query, this.state.params);
                            Object.keys(query).map((key, index) => {
                              if (!query[key]) {
                                delete query[key];
                              }
                            });
                            this.props.dispatch(
                              push({
                                pathname: this.props.match.pathname,
                                search: queryString.stringify(query)
                              })
                            );
                          }
                        );
                      }}
                    >
                      {T.translate("activated")}
                    </li>
                    <li
                      className={classNames("select__item text-height", {
                        active:
                          this.state.params.status &&
                          this.state.params.status === "inactive"
                      })}
                      onClick={() => {
                        this.setState(
                          {
                            toggleOpenOrder: false,
                            params: update(this.state.params, {
                              status: {
                                $set: "inactive"
                              }
                            })
                          },
                          () => {
                            query = Object.assign({}, query, this.state.params);
                            Object.keys(query).map((key, index) => {
                              if (!query[key]) {
                                delete query[key];
                              }
                            });
                            this.props.dispatch(
                              push({
                                pathname: this.props.match.pathname,
                                search: queryString.stringify(query)
                              })
                            );
                          }
                        );
                      }}
                    >
                      {T.translate("inactive")}
                    </li>

                    <li
                      className={classNames("select__item text-height", {
                        active:
                          this.state.params.status &&
                          this.state.params.status === "complete"
                      })}
                      onClick={() => {
                        this.setState(
                          {
                            toggleOpenOrder: false,
                            params: update(this.state.params, {
                              status: {
                                $set: "complete"
                              }
                            })
                          },
                          () => {
                            query = Object.assign({}, query, this.state.params);
                            Object.keys(query).map((key, index) => {
                              if (!query[key]) {
                                delete query[key];
                              }
                            });
                            this.props.dispatch(
                              push({
                                pathname: this.props.match.pathname,
                                search: queryString.stringify(query)
                              })
                            );
                          }
                        );
                      }}
                    >
                      {T.translate("complete")}
                    </li>
                  </ul>
                </DropdownMenu>
              </Dropdown>
            </div>
          </header>
        </div>
        <div className="users-list__wrap row">
          <div className="table__wrap  table--theme  settings__table">
            <table className="footable customers-dashboard__footable-toggle customers-dashboard__notices table--break-small">
              <thead>
                <tr>
                  <th
                    className="text-left"
                    onClick={() => {
                      this.toggleSortSign("signName");
                    }}
                  >
                    {T.translate("name")}{" "}
                    <i
                      className={classNames("fa", {
                        "fa-caret-down":
                          this.state.propertyName === "signName" &&
                          !this.state.reverse,
                        "fa-caret-up":
                          this.state.propertyName !== "signName" ||
                          this.state.reverse
                      })}
                    ></i>
                  </th>
                  <th className="text-left" data-hide="phone,tablet">
                    {T.translate("publication.shared-by")}
                  </th>
                  <th
                    className="text-left"
                    onClick={() => {
                      this.toggleSortSign("signCreadtedAt");
                    }}
                  >
                    {T.translate("created-date")}{" "}
                    <i
                      className={classNames("fa", {
                        "fa-caret-down":
                          this.state.propertyName === "signCreadtedAt" &&
                          !this.state.reverse,
                        "fa-caret-up":
                          this.state.propertyName !== "signCreadtedAt" ||
                          this.state.reverse
                      })}
                    ></i>
                  </th>
                  <th
                    className="text-left"
                    data-hide="phone,tablet"
                    onClick={() => {
                      this.toggleSortSign("signDeadline");
                    }}
                  >
                    {T.translate("deadline")}{" "}
                    <i
                      className={classNames("fa", {
                        "fa-caret-down":
                          this.state.propertyName === "signDeadline" &&
                          !this.state.reverse,
                        "fa-caret-up":
                          this.state.propertyName !== "signDeadline" ||
                          this.state.reverse
                      })}
                    ></i>
                  </th>
                  <th className="text-center" data-hide="phone">
                    {T.translate("link")}
                  </th>
                </tr>
              </thead>
              <tbody>
                {this.props.signs !== undefined
                  ? this.props.signs.map(sign => (
                      <tr key={sign.id}>
                        <td
                          data-header={T.translate("name")}
                          className="text-left"
                        >
                          {sign.publication.name}
                        </td>
                        <td
                          data-header={T.translate("publication.shared-by")}
                          className="text-left"
                        >
                          {sign.sharedBy.email}
                        </td>
                        <td
                          data-header={T.translate("created-date")}
                          className="text-left"
                        >
                          {moment(sign.createdAt).format("YYYY-MM-DD")}
                        </td>
                        <td
                          data-header={T.translate("deadline")}
                          className="text-left"
                        >
                          {moment(sign.deadline).format("YYYY-MM-DD")}
                        </td>
                        <td
                          data-header={T.translate("link")}
                          className="text-center"
                        >
                          <a
                            className="customer-portals__link"
                            target="_blank"
                            href={`${config.APP_URL}/companies/${this.state.companyId}/publications/${sign.publication.id}`}
                          >
                            <i className="fa fa-link" aria-hidden="true"></i>
                          </a>
                        </td>
                      </tr>
                    ))
                  : null}
              </tbody>
            </table>
          </div>
          <ul className="pagination row cf">
            <li className="line-component">
              {params.page > 0 ? (
                <button
                  onClick={() => {
                    this.filterSign({
                      page: parseInt(params.page | 0, 10) - 1
                    });
                  }}
                  className="button button--system"
                >
                  <i className="fa fa-chevron-left"></i>
                  {T.translate("previous")}
                </button>
              ) : null}
            </li>
            <li className="line-component--omega">
              {this.state.more ? (
                <button
                  onClick={() => {
                    this.filterSign({
                      page: parseInt(params.page | 0, 10) + 1
                    });
                  }}
                  className="button button--system icon-right"
                > {T.translate("next")}
                  <i className="fa fa-chevron-right"></i>
                </button>
              ) : null}
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Esign);
