import {
  GET_COMPANY,
  GET_COMPANY_INFO,
  GET_COMPANY_LOGO,
  GET_COMPANY_LOGO_PDF,
  QUERY_COMPANY_FILES,
  QUERY_COMPANY_CATEGORIES,
  QUERY_COMPANY_TASK_TYPES,
  QUERY_COMPANY_TAGS,
  QUERY_COMPANY_PRIORITIES
} from "../../actions/company/CompanyActions";

const Company = (state = { categories: [], priorities: [], files: [], info: null, logo: null, logoPdf: null, taskTypes: [], tags: [] }, action) => {
  switch (action.type) {
    case GET_COMPANY + "_FULFILLED":
      return {
        ...state,
        company: action.payload.body,
      };

    case GET_COMPANY_INFO + "_FULFILLED":
      return {
        ...state,
        info: action.payload.body,
      };

    case GET_COMPANY_LOGO + "_FULFILLED":
      return {
        ...state,
        logo: action.payload.body,
      };

    case GET_COMPANY_LOGO_PDF + "_FULFILLED":
      return {
        ...state,
        logoPdf: action.payload.body,
      };

    case QUERY_COMPANY_FILES + "_FULFILLED":
      return {
        ...state,
        files: action.payload.body,
      };
    case QUERY_COMPANY_CATEGORIES + "_FULFILLED":
      return {
        ...state,
        categories: action.payload.body,
      };
    case QUERY_COMPANY_PRIORITIES + "_FULFILLED":
      return {
        ...state,
        priorities: action.payload.body,
      };
    case QUERY_COMPANY_TASK_TYPES + "_FULFILLED":
      return {
        ...state,
        taskTypes: action.payload.body,
      };
    case QUERY_COMPANY_TAGS + "_FULFILLED":
      return {
        ...state,
        tags: action.payload.body,
      };

    default:
      return state || {};
  }
};

export default Company;
