import request from "superagent";
import { API_BASE } from "../config.js";
import { store } from "../index";
import { logout, REDIRECT_PATTERN } from "../actions/auth";
import { history } from "../index";

const stateChangeStatus = r => {
  const currentLocation = history.location;
  if (!REDIRECT_PATTERN.test(currentLocation.pathname)) {
    if (r && r.status >= 400) {
      switch (r.status) {
        case 401:
          store.dispatch(logout());
          break;

        case 403:
          if (r.req && r.req.method === "GET") {
            history.replace("/403");
          }
          break;

        case 404:
          if (r.req && r.req.method === "GET") {
            history.replace("/404");
          }
          break;

        default:
          if (r.req && r.req.method === "GET") {
            history.replace("/error");
          }
          break;
      }
    }
  }
};

const APIUtils = {
  token: window.localStorage.getItem("token"),
  get(path) {
    return new Promise((resolve, reject) => {
      request
        .get(API_BASE + path)
        .set("Authorization", `Bearer ${this.token}`)
        // .withCredentials()
        .end((err, res) => {
          if (err || !res.ok) {
            stateChangeStatus(res);
            reject(err);
          } else {
            resolve(res);
          }
        });
    });
  },

  post(path, body) {
    return new Promise((resolve, reject) => {
      request
        .post(API_BASE + path, body)
        .set("Authorization", `Bearer ${this.token}`)
        // .withCredentials()
        .end((err, res) => {
          if (err || !res.ok) {
            stateChangeStatus(res);
            reject(res);
          } else {
            resolve(res);
          }
        });
    });
  },

  patch(path, body) {
    return new Promise((resolve, reject) => {
      request
        .patch(API_BASE + path, body)
        .set("Authorization", `Bearer ${this.token}`)
        // .withCredentials()
        .end((err, res) => {
          if (err || !res.ok) {
            stateChangeStatus(res);
            reject(err);
          } else {
            resolve(res);
          }
        });
    });
  },

  put(path, body) {
    return new Promise((resolve, reject) => {
      request
        .put(API_BASE + path, body)
        .set("Authorization", `Bearer ${this.token}`)
        // .withCredentials()
        .end((err, res) => {
          if (err || !res.ok) {
            stateChangeStatus(res);
            reject(err);
          } else {
            resolve(res);
          }
        });
    });
  },

  del(path) {
    return new Promise((resolve, reject) => {
      request
        .del(API_BASE + path)
        .set("Authorization", `Bearer ${this.token}`)
        // .withCredentials()
        .end((err, res) => {
          if (err || !res.ok) {
            stateChangeStatus(res);
            reject(err);
          } else {
            resolve(res);
          }
        });
    });
  }
};

export default APIUtils;
