import {
    QUERY_COMPANY_USER_ROLES
  } from "../../../actions/company/user-roles/CompanyUserRoleActions";
  
  const CompanyUserRole = (state = { userRoles: null }, action) => {
    switch (action.type) {
        case QUERY_COMPANY_USER_ROLES + "_FULFILLED":
            return {
                ...state,
                userRoles: action.payload.body
            };
        default:
            return state;
    }
  };
  
  export default CompanyUserRole;
  