import { QUERY_NOTICE_SERVICE, QUERY_NOTICE_DETAIL } from "../../../actions/company/list/CompanyNoticeAction";

const NoticeService = (state = { notices: [], hasMore: false, noticeDetail: {}}, action) => {
    switch (action.type) {
        case QUERY_NOTICE_SERVICE + "_FULFILLED":
            return {
                ...state,
                notices: action.payload.body.slice(0, 20),
                hasMore: action.payload.body.length > 20 ? true : false
            };
        case QUERY_NOTICE_DETAIL + "_FULFILLED":
            return {
                ...state,
                noticeDetail: action.payload.body
            };

        default:
            return state;
    }

};

export default NoticeService;

