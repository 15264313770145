import qs from "qs";
import APIUtils from "../../../utils/APIUtils";

export const QUERY_COMPANY_GROUPS = "QUERY_COMPANY_GROUPS";
export const QUERY_COMPANY_CUSTOMER_PORTALS = "QUERY_COMPANY_CUSTOMER_PORTALS";
export const QUERY_COMPANY_GROUP_PORTALS = "QUERY_COMPANY_GROUP_PORTALS";
export const QUERY_COMPANY_PORTALS = "QUERY_COMPANY_PORTALS";
export const GET_COMPANY_GROUP = "GET_COMPANY_GROUP";
export const CREATE_COMPANY_GROUP = "CREATE_COMPANY_GROUP";
export const UPDATE_COMPANY_GROUP = "UPDATE_COMPANY_GROUP";
export const DELETE_COMPANY_GROUP = "DELETE_COMPANY_GROUP";
export const QUERY_COMPANY_USER_GROUPS = "QUERY_COMPANY_USER_GROUPS";
export const QUERY_COMPANY_USER_PORTALS = "QUERY_COMPANY_USER_PORTALS";
export const QUERY_COMPANY_GROUP_USERS = "QUERY_COMPANY_GROUP_USERS";
export const CREATE_COMPANY_GROUP_USER = "CREATE_COMPANY_GROUP_USER";
export const DELETE_COMPANY_GROUP_USER = "DELETE_COMPANY_GROUP_USER";
export const QUERY_COMPANY_GROUP_PUBLICATIONS = "QUERY_COMPANY_GROUP_PUBLICATIONS";
export const LIMIT = 20

export const queryCompanyGroups = (companyId, params = {}) => {
  let queryStr = qs.stringify(params, { addQueryPrefix: true });
  let typeQuery = QUERY_COMPANY_GROUPS
  if(params.customerConnected === true){
    typeQuery = QUERY_COMPANY_CUSTOMER_PORTALS
  }else{
    if(params.customerConnected === false){
      typeQuery = QUERY_COMPANY_GROUP_PORTALS
    }else{
      if(params.userId){
        if(params.type === "HIDDEN"){
          typeQuery = QUERY_COMPANY_USER_GROUPS  
        }else{
          typeQuery = QUERY_COMPANY_USER_PORTALS  
        }
      }else{
        if(params.type === "HIDDEN"){
          typeQuery = QUERY_COMPANY_GROUPS
        }else{
          typeQuery = QUERY_COMPANY_PORTALS  
        }
      }
    }
  }
  return {
    type: typeQuery,
    payload: APIUtils.get(`/companies/${companyId}/groups${queryStr}`)
  };
};

export const getCompanyGroup = (companyId, groupId) => ({
  type: GET_COMPANY_GROUP,
  payload: APIUtils.get(`/companies/${companyId}/groups/${groupId}`)
});

export const createCompanyGroup = (companyId, group) => ({
    type: CREATE_COMPANY_GROUP,
    payload: APIUtils.post(
        `/companies/${companyId}/groups${
            group.type ? "?type=" + group.type : ""
        }`,
        group
    ),
});

export const updateCompanyGroup = (companyId, group) => ({
    type: UPDATE_COMPANY_GROUP,
    payload: APIUtils.put(
        `/companies/${companyId}/groups/${group.id}`,
        group
    ),
});

export const deleteCompanyGroup = (companyId, groupId) => ({
    type: DELETE_COMPANY_GROUP,
    payload: APIUtils.del(
        `/companies/${companyId}/groups/${groupId}`
    ),
});

export const queryGroupUsers = (companyId, groupId, params = {}) => {
  let queryStr = qs.stringify(params, { addQueryPrefix: true });
  return {
    type: QUERY_COMPANY_GROUP_USERS,
    payload: APIUtils.get(`/companies/${companyId}/groups/${groupId}/users${queryStr}`)
  };
};

export const createGroupUser = (companyId, groupId, user) => ({
  type: CREATE_COMPANY_GROUP_USER,
  payload: APIUtils.post(`/companies/${companyId}/groups/${groupId}/users`, user)
});

export const deleteGroupUser = (companyId, groupId, userId) => ({
  type: DELETE_COMPANY_GROUP_USER,
  payload: APIUtils.del(`/companies/${companyId}/groups/${groupId}/users/${userId}`)
});

export const queryGroupPublications = (companyId, groupId, params = {}) => {
  let queryStr = qs.stringify(params, { addQueryPrefix: true });
  return {
    type: QUERY_COMPANY_GROUP_PUBLICATIONS,
    payload: APIUtils.get(`/companies/${companyId}/groups/${groupId}/publications${queryStr}`)
  };
};
