import {
    QUERY_COMPANY_INVESTIGATION_TEMPLATES
  } from "../../../actions/company/investigation-templates/CompanyInvestigationTemplateActions";
  
  const CompanyInvestigationTemplate = (state = { investigationTemplates: [] }, action) => {
    switch (action.type) {
      case QUERY_COMPANY_INVESTIGATION_TEMPLATES + "_FULFILLED":
        return {
          ...state,
          investigationTemplates: action.payload.body
        };
  
      default:
        return state;
    }
  };
  
  export default CompanyInvestigationTemplate;
  