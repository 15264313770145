import config from "../config";
import { replace } from "connected-react-router";
import APIUtils from "../utils/APIUtils";
import T from "i18n-react";
import qs from "qs";
import Notifications from "react-notification-system-redux";
import { history } from "../index";
const loginSuccess = (token, userId) => ({
  type: "LOGIN_SUCCESS",
  userId: userId,
  token: token
});

const loginProgress = () => ({
  type: "LOGIN_PROGRESS"
});

const loginFailure = (code, phone) => ({
  type: "LOGIN_FAILURE",
  code: code,
  phone: phone
});

export const REDIRECT_PATTERN = /\/login|\/reset-password/i;

export const logout = () => dispatch => {
  dispatch({
    type: "LOGIN_FAILURE"
  });
  dispatch(replace("/login"));
};

export const login = (email, password) => dispatch => {
  dispatch(loginProgress());
  const user = { email, password };
  APIUtils.post("/authorize?appId=" + config.APP_ID, user).then(
    r => {
      dispatch(loginSuccess(r.body.token, r.body.userId));
      const location = history.location;
      const query = qs.parse(location.search, { ignoreQueryPrefix: true });
      if (REDIRECT_PATTERN.test(location.pathname)) {
        let redirect = "/";
        if (query.redirect && !REDIRECT_PATTERN.test(query.redirect)) {
          redirect = query.redirect;
        }

        window.location = redirect;
      }
    },
    err => {
      let message = T.translate("error.network-connectivity-issues");
      if (err && err.body.error) {
        switch (err.body.error.code) {
          case 1101:
            message = T.translate("login.err-missing-app-id");
            break;
          case 1102:
            message = T.translate("login.err-invalid-app-id");
            break;
          case 1103:
            message = T.translate("login.err-invalid-credentials");
            break;
          case 1106:
            message = T.translate("login.err-invalid-auth-request-body");
            break;
          case 1107:
            message = T.translate("login.err-token-exists");
            break;
          case 1109:
          case 1108:
            message = "";
            break;
        }
      }


      if (message) {
        dispatch(
          Notifications.error({
            title: "",
            message: message,
            position: "tc",
            autoDismiss: 3
          })
        );
      }

      dispatch(
        loginFailure(
          err ? err.body.error.code : "",
          err ? err.body.error.message : ""
        )
      );
    }
  );
};

const resetSuccess = () => ({
  type: "RESET_PASSWORD_SUCCESS",
  msg: T.translate("user.request-password-reset-success")
});

const resetFailure = () => ({
  type: "RESET_PASSWORD_FAILURE",
  msg: T.translate("user.request-password-reset-failure")
});

export const reset = email => dispatch => {
  APIUtils.post("/users/forgot-password", { Email: email }).then(
    () => {
      dispatch(
        Notifications.success({
          title: "",
          message: T.translate("user.request-password-reset-success"),
          position: "tc",
          autoDismiss: 5
        })
      );
      dispatch(resetSuccess());
      dispatch(replace("/login"));
    },
    () => {
      dispatch(
        Notifications.error({
          title: "",
          message: T.translate("user.request-password-reset-failure"),
          position: "tc",
          autoDismiss: 5
        })
      );
      dispatch(resetFailure());
    }
  );
};

export const verifySMS = code => dispatch => {
  APIUtils.post(`/authorize-sms?appId=${config.APP_ID}`, { sms: code }).then(
    r => {
      dispatch(loginSuccess(r.body.token, r.body.userId));
      const location = history.location;
      const query = qs.parse(location.search, { ignoreQueryPrefix: true });
      if (REDIRECT_PATTERN.test(location.pathname)) {
        let redirect = "/";
        if (query.redirect && !REDIRECT_PATTERN.test(query.redirect)) {
          redirect = query.redirect;
        }

        window.location = redirect;
      }
    },
    () => {
      dispatch(
        Notifications.error({
          title: "",
          message: T.translate("login.sms-wrong-code"),
          position: "tc",
          autoDismiss: 3
        })
      );
    }
  );
};
