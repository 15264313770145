import {
    GET_COMPANY_SKILL,
    QUERY_COMPANY_SKILLS,
    LIMIT,
    QUERY_OBJECT_SKILLS
} from "../../../actions/company/skills/CompanySkillActions";

const CompanySkills = (
    state = {
        skills: [],
        objectSkills: [],
        hasMore: false,
        hasMoreObSkills: false,
        skill: null,
    },
    action
) => {
    switch (action.type) {

        case QUERY_COMPANY_SKILLS + "_FULFILLED":
            return {
                ...state,
                skills: action.payload.body.slice(0, LIMIT),
                hasMore: action.payload.body.length > LIMIT ? true : false,
            }

        case GET_COMPANY_SKILL + "_FULFILLED":
            return {
                ...state,
                skill: action.payload.body
            }

        case QUERY_OBJECT_SKILLS + "_FULFILLED":
            return {
                ...state,
                objectSkills: action.payload.body.slice(0, LIMIT),
                hasMoreObSkills: action.payload.body.length > LIMIT ? true : false,
            }

        default:
            return state;
    }
};

export default CompanySkills;
