import Root from "./root";
import Login from "./auth";
import SelectCompany from "./company/SelectCompany";
import Company from "./company/company";
import loading from "./loader";
import UserProfile from "./user/profile";
import uploadEnQueueFiles from "./upload";
import CompanyUserInfo from "./company/user/user";
import Tasks from "./company/tasks/tasks";
import CompanyMessage from "./company/messages/CompanyMessageReducer";
import CompanyCustomer from "./company/customers/CompanyCustomerReducer";
import CompanyCustomerPortal from "./company/customer-portals/CompanyCustomerPortalReducer";
import SystemNotifications from "./notifications";
import webSocket from "./websocket";
import CompanyUnit from "./company/units/CompanyUnitReducer";
import CompanyUser from "./company/users/CompanyUserReducers";
import Checklist from "./company/checklists/CompanyChecklistReducers";
import emailTemplates from "./company/email-templates/CompanyEmailTemplateReducer";
import CompanyGroup from "./company/groups/CompanyGroupReducer";
import CompanyGroupCategory from "./company/groups/CompanyGroupCategoryReducer";
import CompanyInvestigationUser from "./company/investigation-users/CompanyInvestigationUserReducer";
import CompanyInvestigationTemplate from "./company/investigation-templates/CompanyInvestigationTemplateReducer";
import CompanyFormGroup from "./company/form-groups/CompanyFormGroupReducer";
import UserLoginLog from "./login-logs/LoginLogReducer";
import AddressBook from "./address-books/AddressBookReducer";
import CompanyUserRole from "./company/user-roles/CompanyUserRoleReducer";
import NoticeService from "./company/list/CompanyNoticeReducer";
import InvestService from "./company/list/CompanyInvestigationReducer"
import EsignService from "./company/list/CompanyEsignReducer";
import CompanySkills from "./company/skills/CompanySkillReducer";
import CompanyFlowVerifies from "./company/flow-verifies/CompanyFlowVerifyReducer";

export default {
  loading,
  Root,
  Company,
  Login,
  SelectCompany,
  UserProfile,
  CompanyUserInfo,
  uploadEnQueueFiles,
  Tasks,
  CompanyMessage,
  CompanyCustomer,
  CompanyCustomerPortal,
  SystemNotifications,
  webSocket,
  CompanyUnit,
  CompanyUser,
  Checklist,
  emailTemplates,
  CompanyGroup,
  CompanyGroupCategory,
  CompanyInvestigationUser,
  CompanyInvestigationTemplate,
  CompanyFormGroup,
  UserLoginLog,
  AddressBook,
  CompanyUserRole,
  NoticeService,
  InvestService,
  EsignService,
  CompanySkills,
  CompanyFlowVerifies,
};
