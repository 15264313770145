import {
  QUERY_MESSAGES
} from "../../../actions/company/messages/CompanyMessageActions";

const CompanyMessage = (state = { messages: [] }, action) => {
  switch (action.type) {
    case QUERY_MESSAGES + "_FULFILLED":
      return {
        ...state,
        messages: action.payload.body
      };

    default:
      return state;
  }
};

export default CompanyMessage;
