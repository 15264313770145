import {
    QUERY_COMPANY_GROUPS,
    QUERY_COMPANY_USER_GROUPS,
    QUERY_COMPANY_USER_PORTALS,
    QUERY_COMPANY_PORTALS,
    QUERY_COMPANY_CUSTOMER_PORTALS,
    QUERY_COMPANY_GROUP_PORTALS,
    GET_COMPANY_GROUP,
    QUERY_COMPANY_GROUP_USERS,
    QUERY_COMPANY_GROUP_PUBLICATIONS,
    LIMIT
  } from "../../../actions/company/groups/CompanyGroupActions";
  let isAll = false
  
  const CompanyGroup = (state = { 
    groups: [], 
    userGroups: [], 
    userPortals: [], 
    portals: [], 
    customerPortals: [], 
    hasMoreCustomer: false, 
    groupPortals: [] ,
    hasMore: false,
    portal: null,
    members: [],
    groupPublications: []
  }, action) => {
    switch (action.type) {
      case QUERY_COMPANY_GROUPS + "_FULFILLED":
        return {
          ...state,
          groups: action.payload.body
        };
      case QUERY_COMPANY_USER_GROUPS + "_FULFILLED":
        return {
          ...state,
          userGroups: action.payload.body
        };
      case QUERY_COMPANY_USER_PORTALS + "_FULFILLED":
        return {
          ...state,
          userPortals: action.payload.body
        };
      case QUERY_COMPANY_PORTALS + "_FULFILLED":
        return {
          ...state,
          portals: action.payload.body
        };
      case QUERY_COMPANY_CUSTOMER_PORTALS + "_FULFILLED":
        isAll = action?.payload.req?.url?.includes("all=true") ? true : false
        return {
          ...state,
          customerPortals: isAll ? action.payload.body : action.payload.body.slice(0, LIMIT),
          hasMoreCustomer: isAll ? false : (action.payload.body.length > LIMIT ? true : false),
        };
      case QUERY_COMPANY_GROUP_PORTALS + "_FULFILLED":
        isAll = action?.payload.req?.url?.includes("all=true") ? true : false
        return {
          ...state,
          groupPortals: isAll ? action.payload.body : action.payload.body.slice(0, LIMIT),
          hasMore: isAll ? false : (action.payload.body.length > LIMIT ? true : false),
        };
      case GET_COMPANY_GROUP + "_FULFILLED":
        return {
          ...state,
          portal: action.payload.body
        };
      case QUERY_COMPANY_GROUP_USERS + "_FULFILLED":
        return {
          ...state,
          members: action.payload.body
        };
      case QUERY_COMPANY_GROUP_PUBLICATIONS + "_FULFILLED":
        return {
          ...state,
          groupPublications: action.payload.body
        };
  
      default:
        return state;
    }
  };
  
  export default CompanyGroup;
  