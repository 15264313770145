import qs from "qs";
import APIUtils from "../../../utils/APIUtils";

export const CREATE_CHECKLIST = 'CREATE_CHECKLIST';
export const QUERY_CHECKLISTS = 'QUERY_CHECKLISTS';
export const DELETE_CHECKLIST = 'DELETE_CHECKLIST';

export const createChecklist = (companyId, data) => ({
    type: CREATE_CHECKLIST,
    payload: APIUtils.put(
        `/companies/${companyId}/checklist`,
        data
    )
});

export const queryChecklists = (companyId, params = {}) => {
    return {
        type: QUERY_CHECKLISTS,
        payload: APIUtils.get(
            `/companies/${companyId}/checklist?${qs.stringify(params)}`
        )
    };
};

export const deleteChecklist = (companyId, checklistID) => ({
    type: DELETE_CHECKLIST,
    payload: APIUtils.del(
        `/companies/${companyId}/checklist/${checklistID}`
    )
});
