import { QUERY_INVESTIGATION,  } from "../../../actions/company/list/CompanyInvestigationAction";

const InvestService = (state = { lists: []}, action) => {
    switch (action.type) {
        case QUERY_INVESTIGATION + "_FULFILLED":
            return {
                ...state,
                lists: action.payload.body
            };

        default:
            return state;
    }

};

export default InvestService;

