const CompanyUser = (state, action) => {
    switch (action.type) {
        case "GET_COMPANY_USER_PENDING":
            return {
                ...state,
                companyUser: null
            };
        case "GET_COMPANY_USER_FULFILLED":
            return {
                ...state,
                companyUser: action.payload.body
            };

        default:
          return state || {};
    }
};

export default CompanyUser;
