const SystemNotifications = (state, action) => {
  switch (action.type) {
    case "QUERY_NOTIFICATIONS_FULFILLED":
      let hasMore = false;
      if (action.payload.body.notifications.length === 51) {
        action.payload.body.notifications.pop();
        hasMore = true;
      }
      return {
        ...state,
        data: action.payload.body,
        hasMore: hasMore
      };
    case "RECEIVE_NEW_NOTIFICATION":
      return {
        ...state,
        newNotification: action.message
      };
    case "UPDATE_READ_NOTIFICATION":
      return {
        ...state,
        readNotification: action.message
      }
    default:
      return state || false;
  }
};

export default SystemNotifications;
