import React from "react";
import { connect } from "react-redux";
import { Popover } from "reactstrap";
import UserAvatar from "./UserAvatar";

export class UserPopover extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      popoverOpen: false,
      user: this.props.user
    };
    this.toggleUser = this.toggleUser.bind(this);
  }

  toggleUser() {
    this.setState({
      popoverOpen: !this.state.popoverOpen
    });
  }

  render() {
    let { user } = this.state;
    return (
      <div
        className="watchers__person"
        id={"popover-user-" + user.id + "-" + this.props.keyObj}
        onClick={() => {
          this.toggleUser();
        }}
      >
        <button className="watchers__button" type="button">
          <UserAvatar
            user={user}
            moreClasses="watchers__picture"
            title={user.name}
          />
          <Popover
            isOpen={this.state.popoverOpen}
            target={"popover-user-" + user.id + "-" + this.props.keyObj}
            container="inline"
            placement="bottom"
            toggle={() => {
              this.toggleUser();
            }}
          >
            <div className="popover__wrap">
              <UserAvatar
                user={user}
                moreClasses="popover__image"
                title={user.name}
              />

              <div className="popover__content">
                <h6 className="popover__header">
                  {user.name}
                </h6>
                <span title={user.email} className="ellipsis">
                  {user.email}
                </span>
              </div>
              {this.props.onDelete && !this.props.disabled ? 
              <button className="button button--negative icon-only popover__button" onClick={() => {
                if (typeof this.props.onDelete === 'function') {
                  this.props.onDelete()
                }
                this.toggleUser()
              }}>
                <i className="fa fa-trash-o" />
              </button>
              : null}
            </div>
          </Popover>
        </button>
      </div>
    );
  }
}

export default connect()(UserPopover);