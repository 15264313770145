import React from "react";
import { connect } from "react-redux";
import config from "../config";

const mapStateToProps = ({ UserProfile: { avatarDecache = "" } }) => ({
  avatarDecache
});
export class UserAvatar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showImage: false
    };
  }

  firstChar = n => n.substr(0, 1);
  upperCase = c => c.toUpperCase();
  getParts = user => {
    if (typeof user.name === "string" && user.name.trim().length > 0) {
      return user.name.split(" ");
    } else if (typeof user.email === "string" && user.email.trim().length > 0) {
      return user.email.split(/[^a-z0-9_]/i);
    } else {
      return ["U"];
    }
  };

  getInitials = user =>
    this.getParts(user)
      .map(this.firstChar)
      .map(this.upperCase)
      .join("")
      .substr(0, 3);

  showAvatar() {
    this.setState({
      showImage: true
    });
  }

  render() {
    return (
      <div
        className={
          "user-avatar " +
          (this.props.moreClasses ? this.props.moreClasses : "")
        }
      >
        <div ref="userAvatarInitials" className="user-avatar__initials">
          <span>
            {this.getInitials(this.props.user)}
          </span>
        </div>
        {this.props.user.id
          ? <img
              style={{ display: this.state.showImage ? "block" : "none" }}
              src={
                config.API_BASE +
                "/users/" +
                this.props.user.id +
                "/avatar" +
                (this.props.avatarDecache
                  ? "?decache=" + this.props.avatarDecache
                  : "")
              }
              alt={this.props.user.name}
              onLoad={e => {
                this.showAvatar();
              }}
            />
          : null}
      </div>
    );
  }
}

export default connect(mapStateToProps)(UserAvatar);