import uuid from "../../utils/uuid.js";

const UserProfile = (state, action) => {
  switch (action.type) {
    case "USER_AVATAR_CHANGED":
      return {
        ...state,
        avatarDecache: uuid()
      };

    case "GET_USER_PROFILE_FULFILLED":
      return {
        ...state,
        profile: action.payload.body
      };

    case "UPDATE_USER_PROFILE_PENDING":
      return {
        ...state,
        formWaiting: true
      };
    case "UPDATE_USER_PROFILE_FULFILLED":
      return {
        ...state,
        formWaiting: false
      };
    case "COUNTRY_CODE_FULFILLED":
      return {
        ...state,
        countryCode: action.payload.body
      };

    case "GET_ALIAS_EMAIL_FULFILLED": {
      return {
        ...state,
        aliasEmail: action.payload.body
      };
    }

    default:
      return state || {};
  }
};

export default UserProfile;