import {
    LIMIT,
    QUERY_COMPANY_UNITS,
    QUERY_COMPANY_USER_UNITS,
    GET_COMPANY_UNIT,
    UPDATE_COMPANY_UNIT,
    QUERY_COMPANY_UNIT_CATEGORIES,
    QUERY_UNIT_FILES,
    QUERY_COMPANY_SKILLS,
    GET_COUNT_UNITS,
    QUERY_COMPANY_UNIT_PRODUCTS
} from "../../../actions/units/CompanyUnitActions";

const CompanyUnit = (
    state = {
        units: [],
        hasMore: false,
        unit: null,
        unitStatus: [],
        unitCategories: [],
        files: [],
        hasMoreFiles: false,
        unitProducts: [],
        hasMoreProducts: false
    },
    action
) => {
    switch (action.type) {
        case QUERY_COMPANY_UNITS + "_FULFILLED":
            return {
                ...state,
                units: action.payload.body.slice(0, LIMIT),
                hasMore: action.payload.body.length > LIMIT ? true : false,
            };
        case QUERY_COMPANY_USER_UNITS + "_FULFILLED":
            return {
                ...state,
                userUnits: action.payload.body.slice(0, LIMIT),
                hasMore: action.payload.body.length > LIMIT ? true : false,
            };

        case GET_COMPANY_UNIT + "_FULFILLED":
            return {
                ...state,
                unit: action.payload.body,
            };

        case GET_COMPANY_UNIT + "_PENDING":
            return {
                ...state,
                unit: null,
            };

        case QUERY_COMPANY_UNIT_CATEGORIES + "_FULFILLED":
            return {
                ...state,
                unitCategories: action.payload.body,
            };

        case QUERY_UNIT_FILES + "_FULFILLED":
            return {
                ...state,
                files: action.payload.body.slice(0, 20),
                hasMoreFiles: action.payload.body.length == 21,
            };

        case GET_COUNT_UNITS + "_FULFILLED":
            return {
                ...state,
                countUnits: action.payload.body,
            };

        case QUERY_COMPANY_UNIT_PRODUCTS + "_FULFILLED":
            return {
                ...state,
                unitProducts: action.payload.body.slice(0, 100),
                hasMoreProducts: action.payload.body.length == 101,
            };

        default:
            return state;
    }
};

export default CompanyUnit;
