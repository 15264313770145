import React from 'react';
import T from "i18n-react";
import moment from "moment";
import queryString from "query-string";
import classNames from "classnames";
import config from "../config";
import update from "immutability-helper";
import { push } from "connected-react-router";
import { connect } from "react-redux";
import { queryInvestigation } from "../actions/company/list/CompanyInvestigationAction";

const mapStateToProps = ({
  InvestService: { lists }
}) => {
  return {
    lists
  };
};

const mapDispatchToProps = () => dispatch => ({
  dispatch,
  queryInvestigation: (companyId, params ) => dispatch(queryInvestigation(companyId, params))
});

export class Investigation extends React.Component {
  state = {
    propertyName: "listName",
    reverse: false,
    params: {
      q: ""
    },
    limit: 21,
    more: false
  }
  componentDidMount(){
    let query = queryString.parse(this.props.location.search);
    let localSelectedCompany = window.localStorage.selectedCompanyObj ? JSON.parse(window.localStorage.selectedCompanyObj) : null;
    query.page = query.page || 0;
    if(localSelectedCompany){
      this.setState(
          {
              params: query,
              companyId: localSelectedCompany.id
          },
          () => {
              let params = {
                  limit: this.state.limit + 1,
                  offset: query.page * this.state.limit,
                  q: query.q, 
              };
              query = Object.assign({}, query, params);
              delete query.page;
              this.props.queryInvestigation(
                  this.state.companyId,
                  query
              );
          }
      );
      if(query.q !== undefined){
        this.setState({
          params: update(
            this.state.params,
            {
              q: { $set: query.q}
            })
        })
      }
    }
  }
  componentDidUpdate(nextProps) {
    if(this.props.lists !== nextProps.lists){
      let more = false;
      if (this.props.lists.length === (this.state.limit + 1)) {
          more = true;
      }
      this.setState({
          more: more,
          propertyName: 'noticeName',
          reverse: true,
          lists: this.props.lists
      }, ()=>{
          this.toggleSortList(this.state.propertyName);
      });
    }

    if(this.props.location.search !== nextProps.location.search){
      let query = queryString.parse(this.props.location.search, {
              ignoreQueryPrefix: true
          });
      query.page = query.page || 0;
      this.setState(
          {
              params: query
          },
          () => {
              let params = {
                  limit: this.state.limit + 1,
                  offset: query.page * this.state.limit,
                  q: query.q
              };
              query = Object.assign({}, query, params);
              delete query.page;
              this.props.queryInvestigation(
                  this.state.companyId,
                  query
              );
          }
      );
    }
  }
  toggleSortList = (propertyName) => {
      let lists = this.state.lists.sort((a, b) => {
          if (propertyName === 'listCreatedAt') {
              return this.state.reverse ? (a.createdAt < b.createdAt ? -1 : 1) : (a.createdAt >= b.createdAt ? -1 : 1);
          } else if (propertyName === 'listName') {
              return this.state.reverse ? (a.name < b.name ? -1 : 1) : (a.name >= b.name ? -1 : 1);
          } else if (propertyName === 'listEnddate') {
              return this.state.reverse ? (a.periodDate < b.periodDate ? -1 : 1) : (a.periodDate >= b.periodDate ? -1 : 1);
          } 
      });
      this.setState({
          propertyName: propertyName,
          reverse: !this.state.reverse,
          lists: lists
      });
  }
  filterName = (e) => {
    e.preventDefault();
    let query = queryString.parse(this.props.location.search, {
      ignoreQueryPrefix: true
    });
    query = Object.assign({}, query, this.state.params);
    Object.keys(query).map((key, index) => {
        if (!query[key]) {
            delete query[key];
        }
    });
    this.props.dispatch(
      push({
        pathname: this.props.match.pathname,
        search: queryString.stringify(query)
      }
    ))
  }
  filterList(param) {
      let query = param;
      if (param) {
          query = queryString.parse(this.props.location.search, {
              ignoreQueryPrefix: true
          });
          query = Object.assign({}, query, param);
          Object.keys(query).map((key, index) => {
              if (!query[key]) {
                  delete query[key];
              }
          });
      }
      if (!param) {
          query = {}
      }
      this.props.dispatch(
          push({
              pathname: this.props.match.pathname,
              search: queryString.stringify(query)
          })
      );
  }
  render() {
    let query = queryString.parse(this.props.location.search);
    let params = this.state.params;
    return (
      <div className="content-holder">
        <div className="form__wrap--embed cf">
            <header className="">
              <div className="customers-dashboard__header-column filter-left">
                <h1>{T.translate('publication.INVESTIGATION')}</h1>
              </div>
              <div className="customers-dashboard__header-column filter-right">
              <form className="customers-dashboard__filter-wrap" name="filterNoticeForm"
                  onSubmit={this.filterName} >
                  <div className="select-publication mw-typeahead--has-button">
                    <input  type="text" 
                            placeholder={T.translate('notices.filter-by-name')}
                            className="mw-typeahead__input" 
                            value={this.state.params.q}
                            onChange={(e) => {
                              this.setState({
                                params: update(
                                  this.state.params,
                                  {
                                    q: { $set: e.target.value }
                                  }
                                )
                              })
                            }}/>
                    </div>
                  <button className="button mw-typeahead__button" type="submit">{T.translate('search')}</button>
              </form>
              </div>
            </header>
          </div>
        <div className="users-list__wrap row">
            <div className="table__wrap  table--theme  settings__table">
              <table className="footable customers-dashboard__footable-toggle customers-dashboard__notices table--break-small" >
                <thead>
                  <tr>
                    <th className="text-left"
                        onClick={()=>{
                                this.toggleSortList('listName');
                              }}
                    >
                      {T.translate('name')}{" "} 
                      <i className={classNames("fa", 
                        {"fa-caret-down": (this.state.propertyName === 'listName' && !this.state.reverse), 
                         "fa-caret-up": (this.state.propertyName !== 'listName' || this.state.reverse)})}></i>
                    </th>
                    <th className="text-left" data-hide="phone,tablet">{T.translate('publication.object-number')}</th>
                    <th className="text-left"
                        onClick={()=>{
                                this.toggleSortList('listCreatedAt');
                              }}
                    >{T.translate('created-date')}{" "}
                      <i className={classNames("fa", 
                        {"fa-caret-down": (this.state.propertyName === 'listCreatedAt' && !this.state.reverse), 
                         "fa-caret-up": (this.state.propertyName !== 'listCreatedAt' || this.state.reverse)})}></i>
                    </th>
                    <th className="text-left" data-hide="phone,tablet"
                        onClick={()=>{
                                this.toggleSortList('listEnddate');
                              }}
                    >
                      {T.translate('publication.enddate')} {" "}
                      <i className={classNames("fa", 
                        {"fa-caret-down": (this.state.propertyName === 'listEnddate' && !this.state.reverse), 
                         "fa-caret-up": (this.state.propertyName !== 'listEnddate' || this.state.reverse)})}></i>
                    </th>
                    <th className="text-center" data-hide="phone">
                      {T.translate('link')}{" "}</th>
                  </tr>
                </thead>
                <tbody>
                    {(this.props.lists !== undefined) ? this.props.lists.map((list) =>
                      <tr >
                        <td data-header={T.translate('name')}
                            className="text-left">
                            {list.name}
                        </td>
                        <td data-header={T.translate('publication.object-number')}
                            className="text-left">
                            {list.objectNumber}
                        </td>
                        <td data-header={T.translate('created-date')}
                            className="text-left">
                            {moment(list.createdAt).format("YYYY-MM-DD")}     
                        </td>
                        <td data-header={T.translate('publication.enddate')}
                            className="text-left">
                            {moment(list.periodDate).format("YYYY-MM-DD")} 
                        </td>
                        <td data-header={T.translate('link')}
                            className="text-center">
                          <a className="customer-portals__link"
                             target="_blank"
                             href={`${config.APP_URL}/companies/${this.state.companyId}/publications/${list.id}`}
                          >  
                              <i className="fa fa-link" aria-hidden="true"></i>
                          </a>
                        </td>
                      </tr>
                    ) : null}
                </tbody>
              </table>
            </div>
            <ul className="pagination row cf">
              <li className="line-component">
                {params.page > 0 ? (
                  <button
                    onClick={() => {
                        this.filterList({
                            page: parseInt(params.page, 10) - 1
                        });
                    }}
                    className="button button--system"
                  >
                    <i className="fa fa-chevron-left"></i><span ></span>
                  </button>
                ) : null}
              </li>
              <li className="line-component--omega">
                {this.state.more ? (
                  <button
                    onClick={() => {
                        this.filterList({
                            page: parseInt(params.page, 10) + 1
                        });
                    }}
                    className="button button--system icon-right">
                    <i className="fa fa-chevron-right"></i><span></span>
                  </button>
                ) : null}
              </li>
            </ul>
        </div>
      </div>

    );
  }
}

export default connect(mapStateToProps,mapDispatchToProps )(Investigation);