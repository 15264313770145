import qs from "qs";
import APIUtils from "../../../utils/APIUtils";

export const QUERY_NOTICE_SERVICE = 'QUERY_NOTICE_SERVICE';
export const QUERY_NOTICE_DETAIL = 'QUERY_NOTICE_DETAIL';

export const queryNoticeService = (companyId, params = {}) => {
  let queryStr = qs.stringify(params, { addQueryPrefix: true });
  return {
    type: QUERY_NOTICE_SERVICE,
    payload: APIUtils.get(`/companies/${companyId}/notices${queryStr}`)
  };
};
export const queryNoticeDetail = (companyId, noticeId) => {
  return {
    type: QUERY_NOTICE_DETAIL,
    payload: APIUtils.get(`/companies/${companyId}/notices${noticeId}`)
  };
};
