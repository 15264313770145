import qs from "qs";
import APIUtils from "../../../utils/APIUtils";

export const QUERY_TASKS = "QUERY_TASKS";
export const GET_TASK = "GET_TASK";
export const QUERY_TASK_CATEGORIES = "QUERY_TASK_CATEGORIES";
export const UPDATE_MASSEDIT = "UPDATE_MASSEDIT";

export const queryTasks = (params = {}, companyId) => {
  params.limit = 1000000;
  let queryStr = qs.stringify(params, { addQueryPrefix: true });
  let url = `/companies/reminders${queryStr}`;
  if (companyId) {
    url = `/companies/${companyId}/reminders${queryStr}`;
  }
  return {
    type: QUERY_TASKS,
    payload: APIUtils.get(url)
  };
};


export const getTask = (companyId, taskId) => {
    return {
        type: GET_TASK,
        payload: APIUtils.get(
            `/companies/${companyId}/reminders/${taskId}`
        )
    };
};

export const queryTaskCategories = (companyId) => {
    return {
        type: QUERY_TASK_CATEGORIES,
        payload: APIUtils.get(
            `/companies/${companyId}/reminders/categories`
        )
    };
};

export const updateMassedit = (companyId, data) => ({
    type: UPDATE_MASSEDIT,
    payload: APIUtils.put(
        `/companies/${companyId}/reminders/mass-edit`,
        data
    )
});
