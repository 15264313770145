const uploadEnQueueFiles = (state, action) => {
  switch (action.type) {
    case "FILES_ENQUEUE":
      if (!state.files || !Array.isArray(state.files)) {
        state.files = [action.file];
      } else {
        state.files.push(action.file);
      }
      return state;

    case "FILE_UPLOAD_PROGRESS":
      state.files = state.files.map(f => {
        if (f.id === action.id) {
          f.uploadProgress = action.progress;
        }
        return f;
      });
      return state;

    case "FILE_UPLOAD_SUCCESS":
      state.files = state.files.map(f => {
        if (f.id === action.id) {
          f.uploadStatus = "COMPLETE";
        }
        return f;
      });
      return state;

    case "FILE_UPLOAD_FAILURE": {
      state.files = state.files.map(f => {
        if (f.id === action.id) {
          f.uploadStatus = "ERROR";
        }
        return f;
      });
      return state;
    }

    case "REMOVE_FILE_UPLOADED":
      let idx = state.files.findIndex(f => {
        return f.id === action.id;
      });
      if (idx > -1) {
        state.files.splice(idx, 1);
      }
      return state;

    default:
      return state || [];
  }
};

export default uploadEnQueueFiles;