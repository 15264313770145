import React from "react";
import ReactDOM from "react-dom";

export class TextCarousel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      current: 0,
      counter: 0
    };
    this.navigate = this.navigate.bind(this);
    this.autoGoNext = this.autoGoNext.bind(this);
    this.setCounter = this.setCounter.bind(this);
    this.setCurrent = this.setCurrent.bind(this);
  }

  componentDidMount() {
    if (this.props.notices && this.props.notices.length > 0) {
      this.setState(
        {
          items: ReactDOM.findDOMNode(
            this.refs["TextCarousel"]
          ).getElementsByTagName("p")
        },
        () => {
          this.navigate(0);
          this.autoGoNext(this.navigate);
        }
      );
    }
  }

  autoGoNext = fn => {
    setInterval(() => {
      fn(1);
    }, 8000);
  };

  setCounter = c => {
    this.setState(
      {
        counter: c
      },
      () => {
        this.setCurrent(this.state.counter);
      }
    );
  };

  setCurrent = c => {
    this.setState(
      {
        current: c
      },
      () => {
        this.state.items[this.state.current].classList.add("current");
      }
    );
  };

  navigate = direction => {
    this.state.items[this.state.current].classList.remove("current");
    if (
      direction === 1 &&
      this.state.current >= this.props.notices.length - 1
    ) {
      this.setCounter(0);
    } else {
      this.setCounter(this.state.counter + direction);
    }
  };

  render() {
    return (
      <div>
        {this.props.notices && this.props.notices.length > 0 ? (
          <div ref="TextCarousel" className="publication-header__notices">
            {this.props.notices.map((c, i) => {
              return <p key={i}>{c.message}</p>;
            })}
          </div>
        ) : null}
      </div>
    );
  }
}

export default TextCarousel;
