import qs from "qs";
import APIUtils from "../../../utils/APIUtils";

export const QUERY_COMPANY_USER_ROLES = "QUERY_COMPANY_USER_ROLES";
export const ADD_COMPANY_USER_TO_ROLE = "ADD_COMPANY_USER_TO_ROLE";
export const DELETE_COMPANY_USER_TO_ROLE = "DELETE_COMPANY_USER_TO_ROLE";

export const queryCompanyUserRoles = (companyId, params = {}) => {
  let queryStr = qs.stringify(params, { addQueryPrefix: true });
  return {
    type: QUERY_COMPANY_USER_ROLES,
    payload: APIUtils.get(`/companies/${companyId}/user-roles${queryStr}`)
  };
};

export const addUserToRole = (companyId, userRoleId, user) => ({
  type: ADD_COMPANY_USER_TO_ROLE,
  payload: APIUtils.post(`/companies/${companyId}/user-roles/${userRoleId}/users`, user)
});

export const deleteUserToRole = (companyId, userRoleId, userId) => ({
  type: DELETE_COMPANY_USER_TO_ROLE,
  payload: APIUtils.post(`/companies/${companyId}/user-roles/${userRoleId}/users/${userId}`)
});
