import {
    QUERY_COMPANY_FORM_GROUPS
  } from "../../../actions/company/form-groups/CompanyFormGroupActions";
  
  const CompanyFormGroup = (state = { formGroups: [] }, action) => {
    switch (action.type) {
      case QUERY_COMPANY_FORM_GROUPS + "_FULFILLED":
        return {
          ...state,
          formGroups: action.payload.body
        };
  
      default:
        return state;
    }
  };
  
  export default CompanyFormGroup;
  