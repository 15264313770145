import {
    QUERY_COMPANY_INVESTIGATION_USERS
  } from "../../../actions/company/investigation-users/CompanyInvestigationUserActions";
  
  const CompanyInvestigationUser = (state = { investigationUsers: [] }, action) => {
    switch (action.type) {
      case QUERY_COMPANY_INVESTIGATION_USERS + "_FULFILLED":
        return {
          ...state,
          investigationUsers: action.payload.body
        };
  
      default:
        return state;
    }
  };
  
  export default CompanyInvestigationUser;
  