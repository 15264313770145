import {
  QUERY_COMPANY_CUSTOMERS
} from "../../../actions/company/customers/CompanyCustomerActions";

const CompanyCustomer = (state = { customers: [] }, action) => {
  switch (action.type) {
    case QUERY_COMPANY_CUSTOMERS + "_FULFILLED":
      return {
        ...state,
        customers: action.payload.body
      };

    case "USER_HAVE_NO_CUSTOMER_FEATURE": 
      return {
        ...state,
        customers: []
      }

    default:
      return state;
  }
};

export default CompanyCustomer;
