import { replace } from "connected-react-router";
import T from "i18n-react";
import React from "react";
import Loadable from "react-loadable";
import Notifications from "react-notification-system-redux";
import { connect } from "react-redux";
import LoadingBar from "react-redux-loading-bar";
import { Route } from "react-router-dom";
import { bindActionCreators } from "redux";
import { REDIRECT_PATTERN } from "../actions/auth";
import { changeLanguage, currentUser, queryAllCompanies } from "../actions/root";
import translations from "../locales";
import ComponentLoading from "./ComponentLoading";
import Esign from "./Esign";
import Header from "./Header";
import Investigation from "./Investigation";
import ListReminder from "./ListReminder";
import ModalDetail from "./ModalDetail";

const SelectCompany = Loadable({
  loader: () => import("../pages/Company/SelectCompany"),
  loading: ComponentLoading,
});
const UserProfile = Loadable({
  loader: () => import("../pages/User/profile"),
  loading: ComponentLoading,
});
const CompanyCalendar = Loadable({
  loader: () => import("../pages/Company/Tasks/CompanyCalendar"),
  loading: ComponentLoading,
});
const Login = Loadable({
  loader: () => import("../pages/Login/login"),
  loading: ComponentLoading,
});
const ResetPassword = Loadable({
  loader: () => import("../pages/Login/reset-password"),
  loading: ComponentLoading,
});
const PageError = Loadable({
  loader: () => import("../pages/Errors/PageError"),
  loading: ComponentLoading,
});
const PageNotFound = Loadable({
  loader: () => import("../pages/Errors/PageNotFound"),
  loading: ComponentLoading,
});
const AccessDenied = Loadable({
  loader: () => import("../pages/Errors/AccessDenied"),
  loading: ComponentLoading,
});

const CompanyUnits = Loadable({
  loader: () => import("../pages/units/CompanyUnits"),
  loading: ComponentLoading,
});

const CompanyUnitEdit = Loadable({
  loader: () => import("../pages/units/CompanyUnitEdit"),
  loading: ComponentLoading,
});

const CompanyUnitSkills = Loadable({
  loader: () => import("../pages/units/CompanyUnitSkills"),
  loading: ComponentLoading,
});

const CompanySkills = Loadable({
  loader: () => import("../pages/Company/skills/CompanySkills"),
  loading: ComponentLoading,
});

const CompanySkillLists = Loadable({
  loader: () => import("../pages/Company/skills/CompanySkillLists"),
  loading: ComponentLoading,
});

const CompanySettings = Loadable({
  loader: () => import("../pages/Company/settings/CompanySettings"),
  loading: ComponentLoading,
});

const CompanyCustomerPortals = Loadable({
  loader: () => import("../pages/Company/customer-portals/CompanyCustomerPortals"),
  loading: ComponentLoading,
});

const CompanyCustomerPortalNew = Loadable({
  loader: () => import("../pages/Company/customer-portals/CompanyCustomerPortalNew"),
  loading: ComponentLoading,
});

const CompanyCustomerPortalDetail = Loadable({
  loader: () => import("../pages/Company/customer-portals/CompanyCustomerPortalDetail"),
  loading: ComponentLoading,
});

const mapStateToProps = ({
  Root: { langKey, currentUser },
  notifications,
  uploadEnQueueFiles,
}) => ({
  langKey,
  notifications,
  currentUser,
  uploadEnQueueFiles,
});

const mapDispatchToProps = () => (dispatch) => ({
  dispatch,
  actions: {
    ...bindActionCreators({ currentUser, queryAllCompanies }, dispatch),
  },
});

class App extends React.Component {
  componentWillMount() {
    if (
      window.localStorage &&
      window.localStorage.userId &&
      window.localStorage.token
    ) {
      this.props.actions
        .currentUser(window.localStorage.userId, window.localStorage.token)
        .then((r) => {
          this.props.dispatch(changeLanguage(r.value.body.language));
          window.localStorage.language = r.value.body.language;
          this.props.actions.queryAllCompanies();
          if (REDIRECT_PATTERN.test(this.props.location.pathname)) {
            this.props.dispatch(replace("/"));
          }
        });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.langKey !== nextProps.langKey) {
      T.setTexts(translations[nextProps.langKey]);
    }
  }

  removeUploadFile = (f) => {
    f.abort();
    this.props.dispatch({
      type: "REMOVE_FILE_UPLOADED",
      id: f.id,
    });
  };

  render() {
    let { notifications } = this.props;
    return (
      <div>
        <LoadingBar className="loading-bar" />
        {this.props.currentUser ? (
          <Header location={this.props.location} />
        ) : null}
        <div className="main-view">
          <Route exact path="/" component={SelectCompany} />
          <Route path="/login" component={Login} />
          <Route path="/profile/:userId" component={UserProfile} />
          <Route path="/reset-password" component={ResetPassword} />
          <Route path="/calendar/:companyId?" component={CompanyCalendar} />
          <Route path="/403" component={AccessDenied} />
          <Route path="/404" component={PageNotFound} />
          <Route path="/error" component={PageError} />
          <Route path="/list/reminder" component={ListReminder} />
          <Route path="/list/reminder/:id" component={ModalDetail} />
          <Route path="/list/investigation" component={Investigation} />
          <Route path="/list/esign" component={Esign} />
          <Route exact path="/list/skills" component={CompanySkills} />
          <Route exact path="/list/skills-list" component={CompanySkillLists} />
          <Route exact path="/list/customer-portals" component={CompanyCustomerPortals} />
          <Route exact path="/list/customer-portal-new" component={CompanyCustomerPortalNew} />
          <Route exact path="/list/customer-portals/:customerPortalId" component={CompanyCustomerPortalDetail} />
          <Route exact path="/list/units" component={CompanyUnits} />
          <Route exact path="/list/units-kunskap" component={CompanyUnitSkills} />
          <Route exact path="/list/units/:unitId" component={CompanyUnitEdit} />
          <Route path="/settings" component={CompanySettings} />
        </div>
        {this.props.uploadEnQueueFiles &&
        this.props.uploadEnQueueFiles.files ? (
          <ul className="progress-bar__wrap">
            {this.props.uploadEnQueueFiles.files.map((f, i) => (
              <li
                key={i}
                className={"progress-bar__file " + f.uploadStatus.toLowerCase()}
              >
                <div className="progress-bar__info">
                  {f.uploadStatus === "INPROGRESS" ? (
                    <b>{T.translate("uploading")}:</b>
                  ) : null}
                  {f.uploadStatus === "COMPLETE" ? (
                    <b>{T.translate("complete")}:</b>
                  ) : null}
                  {f.uploadStatus === "ERROR" ? (
                    <b>{T.translate("upload-error")}:</b>
                  ) : null}
                  <span
                    className="progress-bar__file-name"
                    title="{f.uploadName}"
                  >
                    {f.uploadName}
                  </span>
                </div>

                <div className="progress-bar__holder">
                  <div
                    className="progress-bar__bar"
                    style={{ width: f.uploadProgress + "%" }}
                  />
                  <div className="progress-bar__percent">
                    {f.uploadStatus !== "ERROR" ? (
                      <span>{f.uploadProgress}%</span>
                    ) : null}
                  </div>
                </div>

                {f.uploadStatus !== "COMPLETE" ? (
                  <button
                    type="button"
                    className="progress-bar__abort progress-bar__button icon-only"
                    onClick={() => {
                      this.removeUploadFile(f);
                    }}
                  >
                    <i className="fa fa-fw fa-remove" />
                  </button>
                ) : null}

                {f.uploadStatus === "INPROGRESS" ? (
                  <button
                    onClick={(e) => {
                      e.target.parentNode.style.display = "none";
                    }}
                    className="progress-bar__hide progress-bar__button icon-only"
                    type="button"
                  >
                    <i className="fa fa-fw fa-sort-desc" />
                  </button>
                ) : null}
              </li>
            ))}
          </ul>
        ) : null}
        <Notifications notifications={notifications} />
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
