import { QUERY_ESIGN,  } from "../../../actions/company/list/CompanyEsignAction";

const EsignService = (state = { signs: []}, action) => {
    switch (action.type) {
        case QUERY_ESIGN + "_FULFILLED":
            return {
                ...state,
                signs: action.payload.body
            };

        default:
            return state;
    }

};

export default EsignService;

