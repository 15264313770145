import React, { useRef, useEffect, memo } from "react";

export const useOutside = (ref, action) => {
    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                action();
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
};

const ClickOutside = ({ onClickOutside, children }) => {
    const wrapperRef = useRef(null);
    useOutside(
        wrapperRef,
        onClickOutside ||
            function () {
                return;
            }
    );
    return <div ref={wrapperRef}>{children}</div>;
};

export default ClickOutside;
